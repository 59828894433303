import { TreeDataNode } from "antd";

export const permissionsDataNode: TreeDataNode[] = [
    {
        title: 'Configuración',
        key: 'management:menu',
        children: [
            {
                title: 'Empleados',
                key: 'employees:menu',
                children: [
                    {
                        title: 'Normal',
                        key: 'employees:index',
                    },
                    {
                        title: 'Admin',
                        key: 'employees:god',
                    },
                ]
            },
            {
                title: 'Escuelas',
                key: 'organizational-units:index'
            },
            {
                title: 'Plazas',
                key: 'administrative-positions:index'
            },
            {
                title: 'Usuarios Web',
                key: 'users:index'
            },
            {
                title: 'Usuarios Admin',
                key: 'users-bo:index'
            }
        ]
    }
];

export const PermissionsConstants = {
    EMPLOYEES_INDEX: 'employees:index',
    EMPLOYEES_GOD: 'employees:god',
}