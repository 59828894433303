import { Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
  ConfigProvider,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import dataProvider from "@pankod/refine-simple-rest";
import axios, { AxiosInstance, AxiosRequestConfig } from "@pankod/refine-simple-rest/node_modules/axios";
import { authProvider } from "./providers/auth-provider.provider";
import { Login } from "pages/login";
import { DashboardPage } from "pages/dashboard";
import { Sider } from "components/organisms/sider.component";

import './sass/main.scss';

import { API_URL, PERMISSIONS_KEY, TOKEN_KEY } from "constants/environment";
import { UserCreate, UserEdit, UserList, UserShow } from "pages/users";
import { EmployeeCreate, EmployeeEdit, EmployeeList, EmployeeShow } from "pages/employees";
import { accessControlProvider } from "providers/access-control.provider";
import { useState } from "react";
import moment from "moment-timezone";
import locale from "antd/lib/locale/es_ES";
import { CarFilled, IdcardFilled, MailFilled, ProfileFilled, SettingFilled, ShopFilled, SlidersFilled, UserOutlined } from "@ant-design/icons";
import { OrganizationalUnitCreate, OrganizationalUnitEdit, OrganizationalUnitList } from "pages/organizational-units";
import { AdministrativePositionCreate, AdministrativePositionEdit, AdministrativePositionList } from "pages/administrative-positions";
import { PermissionsList } from "pages/permissions/list";
import { CustomPage } from "pages/custom/custom-page";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        if (request.headers) {
            request.headers["Authorization"] = `Bearer ${token}`;
        } else {
            request.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
    }
    return request;
});



function App() {
  const lsPermissions = localStorage.getItem(PERMISSIONS_KEY);
  const [permissions, setPermissions] = useState(lsPermissions || '');
  moment.tz.setDefault('America/Mazatlan');
  
  return (
    <ConfigProvider locale={locale}>
      <Refine
      notificationProvider={notificationProvider}
      Layout={Layout}
      Sider={Sider}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <CustomPage />,
            path: '/custom'
          }
        ] as typeof routerProvider.routes,
      }}
      accessControlProvider={accessControlProvider(permissions)}
      dataProvider={dataProvider(API_URL, axiosInstance)}
      authProvider={authProvider(axiosInstance, setPermissions)}
      LoginPage={Login}
      DashboardPage={DashboardPage}
      reactQueryDevtoolConfig={false}
      resources={[
        {
          name: "cruds",
          icon: <SettingFilled />,
          options: {
            label:"Configuración",
          }
        },
        {
          parentName: "cruds",
          name: "employees",
          icon: <IdcardFilled />,
          create: EmployeeCreate,
          list: EmployeeList,
          edit: EmployeeEdit,
          options: {
            label: "Empleados",
            route: "employees"
          }
        },
        {
          parentName: "cruds",
          name: "organizational-units",
          icon: <ShopFilled />,
          create: OrganizationalUnitCreate,
          list: OrganizationalUnitList,
          edit: OrganizationalUnitEdit,
          options: {
            label: "Unidades Organizacionales",
            route: "organizational-units"
          }
        },
        {
          parentName: "cruds",
          name: "administrative-positions",
          icon: <SlidersFilled />,
          create: AdministrativePositionCreate,
          list: AdministrativePositionList,
          edit: AdministrativePositionEdit,
          options: {
            label: "Plazas",
            route: "administrative-positions"
          }
        },
        {
          parentName: "cruds",
          name: "users",
          icon: <UserOutlined />,
          create: UserCreate,
          list: UserList,
          show: UserShow,
          edit: UserEdit,
          options: {
            label: "Usuarios Web",
            route: "users"
          },
        },
        {
          parentName: "cruds",
          name: "users-bo",
          icon: <UserOutlined />,
          create: UserCreate,
          list: UserList,
          show: UserShow,
          edit: UserEdit,
          options: {
            label: "Usuarios Admin",
            route: "users-bo"
          },
        },
        {
          parentName: "cruds",
          name: "permissions",
          icon: <UserOutlined />,
          list: PermissionsList,
          options: {
            label: "Permisos",
            route: "permissions"
          },
        }
      ]}
    />
    </ConfigProvider>
  );
}

export default App;
